<template>
  <div class="container-fluid">
    <NuxtPage />
    <Loading />
  </div>
</template>

<script setup lang="ts">
onMounted(async () => {})
</script>
